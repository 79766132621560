table {
    border: solid 1px;
    width: 100%;
    border-collapse: collapse;

    td {
        border: solid 1px;
    }

    th {
        border: solid 1px;
    }

    thead {
        font-size: 12px;
        
        th {
            height: 25px;
            min-width: 50px;
            font-weight: bold;
            text-align: left;
            padding: 2px 10px;
        }
    }

    tbody {
        font-size: 12px;

        tr:nth-child(even) {
            background-color: #DDD;
        }

        td {
            height: 25px;
            text-align: left;
            padding: 2px 10px;
        }
    }
}