$input-bg-color: #FFF;
$input-label-color: #555;
$input-color: #333;
$input-validation-text-color: #FF0000;

#client-form-block {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;

    form {
        width: 100%;
        max-width: 500px;
    }

    .form-section {
        width: 100%;
    }

    .form-input {
        margin: 10px 0;
        text-align: left;
        width: 100%;

        label {
            display: block;
            font-size: 12px;
            color: $input-label-color;
        }

        input, select {
            height: 25px;
            border: solid 1px #AAA;
            width: 100%;
            padding: 3px;
            background-color: $input-bg-color;
        }

        select {
            height: 35px;
        }  
        
        p.validation-text {
            display: none;
        }

        &.invalid {
            input, select {
                border-color: $input-validation-text-color;
            }

            p.validation-text {
                display: block;
                font-size: 12px;
                color: $input-validation-text-color
            }
        }
    }

    .selected-products-block {
        margin-top: 10px;
        font-size: 12px;
        font-weight: bold;
    
        ul {
            list-style: none;
            margin-top: 10px;
    
            li {
                color: #666;
            }
        }
    }
}



.selected-product-item {
    display: flex;

    div {
        padding: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 5px 0;
    }

    .selected-product-label {
        width: 150px;
        justify-content:start;
    }

    .selected-product-action {
        height: 20px;
        width: 20px;
        background-color: #CCC;
        color: #FF0000;
        cursor: pointer;

    }
}