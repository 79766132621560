$button-action-gb-color: #09168b;
$button-action-text-color: #FFFFFF;

* {
  margin: 0;
  padding: 0;
}

body {
  // overflow: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 50px;
  pointer-events: none;
}


.App-link {
  color: #61dafb;
}

main {
  display: flex;
  
  #content-container {
    padding: 10px;
    width: 100%;
    max-width: 1000px;

    .page-title-block {
      height: 50px;
      text-align: left;
    }

    .content-block {
      margin-top:10px;
      padding: 15px 30px;
      min-height: 500px;
      border: solid #CCC 1px;
      background-color: #FFFFFF;

      #action-block {
        margin: 10px 0;
        display: flex;
        justify-content: flex-start;
      }

      #dashboard-kpis-block {
        width: 100%;
        display: flex;
        justify-content: center;

        .kpi-item {
          height: 100px;
          width: 200px;
          margin: 10px;
          padding: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: solid #CCC 1px;
          background-color: #daf7ff;

          .kpi-number {
            font-size: 26px;
            font-weight: bold;
          }
        }
      }
    }
  }
}

.button-action {
  padding: 10px 15px;
  border: none;
  background-color: $button-action-gb-color;
  color: $button-action-text-color;
  cursor: pointer;
}

@keyframes donut-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#loading-container {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #7983ff;
  border-radius: 50%;
  animation: donut-spin 1.2s linear infinite;
  margin: 0 auto;
}