$primary-color: #747474;
$secundary-color: #252525;

#nav-bar {
    display: flex;
    flex-direction: column;
    height: auto;
    min-height: 100vh;
    width: 200px;
    background-color: $primary-color;
    

    .nav-action {
        margin: 5px 0;
       
        a {
            color:#FFFFFF;
            text-decoration: none;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: $primary-color;
            width: 100%;
            height: 50px;

            &:hover {
                background-color: $secundary-color;
            }
        }

        &.active {
            a {
                background-color: $secundary-color;
            }
        }

    }
}